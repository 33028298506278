import * as React from 'react'
import * as s from './updated-indication.scss'
import {UpdatedIndicationProps} from '.'

export const UpdatedIndication = ({formattedUpdatedDate, t}: UpdatedIndicationProps) => (
  <div className={s.container}>
    <div className={s.pimple} />
    <div className={s.updatedIndicationText}>
      {t('updated')} {formattedUpdatedDate}
    </div>
  </div>
)
