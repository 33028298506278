import * as React from 'react'
import {Error} from '../../../icons'
import {SectionNotification} from '../section-notification'
import {UpdatedIndication} from '../updated-indication'
import * as s from './indications.scss'
import {IndicationsProps} from '.'

export const Indications = ({
  canceled,
  t,
  formattedUpdatedDate,
  scheduleUpdatedIndicationEnabled,
}: IndicationsProps) => {
  const shouldUpdatedIndicationShow = scheduleUpdatedIndicationEnabled && !canceled && formattedUpdatedDate
  return (
    <>
      {canceled ? (
        <SectionNotification
          className={s.cancelNotification}
          icon={<Error className={s.canceledIcon} />}
          text={t('itemCanceled')}
        />
      ) : null}
      {shouldUpdatedIndicationShow ? <UpdatedIndication t={t} formattedUpdatedDate={formattedUpdatedDate} /> : null}
    </>
  )
}
